import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'formly-field-Radio',
    template: `
    <ion-radio-group [formControl]="formControl" [formlyAttributes]="field">
    <ion-item [disabled]="field.templateOptions.disabled" *ngFor="let option of field.templateOptions.options">
        {{option.label}} <ion-radio slot="end" [value]="option.value" ></ion-radio>
</ion-item>
</ion-radio-group>
 `,
})

export class FormlyFieldIonRadio extends FieldType {

}
