import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '../../services/translate.service';
import { RequiredPropertiesService } from '../../services/required-properties.service';
import { AnnouncementService } from '../../services/announcement.service';
import { StatusName } from '../../interfaces/required_properties/status-name';
import { PropertyType } from '../../interfaces/announcement/property-type';
import { AnnouncementType } from 'src/app/interfaces/announcement/announcement-type';
import { ModalController, AlertController } from '@ionic/angular';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup, FormControl } from '@angular/forms';
import { RequiredPropertyModel } from '../../models/required-property.model';
import { LocationModel } from '../../models/location.model';
import { PropertyTypeModel } from 'src/app/models/property-type.model';
import { AnnouncementTypeModel } from 'src/app/models/announcement-type.model';
import { UserCreateComponent } from '../user-create/user-create.component';
import { UsersService } from '../../services/users.service';
import { startWith } from 'rxjs/operators';
import { RequiredProperty } from 'src/app/interfaces/required_properties/required-property';
import { User } from 'src/app/interfaces/users/user';
import { SingleAntComponent } from '../../pages/single-ant/single-ant.component';
import { AddEventComponent } from '../../pages/add-event/add-event.component';

@Component({
	selector: 'app-required-properties',
	templateUrl: './required-properties.component.html',
	styleUrls: ['./required-properties.component.scss']
})
export class RequiredPropertiesComponent implements OnInit {
	/* tslint:disable */
	@Input() user: User;
	@Input() current_required_property: RequiredProperty;
	edited: boolean = false;
	addField: boolean = false;
	@Output() statusChanged = new EventEmitter<any>();
	obj = {};
	selected_array = [];
	active = 0
	selected = 0;
	texts: any;
	props;
	statuses: Array<StatusName> = [];
	property_types: Array<PropertyType> = [];
	announcement_types: Array<AnnouncementType> = [];
	requiredPropertyForm: FormGroup = new FormGroup({});
	requiredPropertyModel: RequiredPropertyModel = new RequiredPropertyModel();
	locationModel: LocationModel = new LocationModel();
	propertyTypeModel: PropertyTypeModel = new PropertyTypeModel();
	announcementTypeModel: AnnouncementTypeModel = new AnnouncementTypeModel();
	locations: {
		states: Array<any>;
		communities: Array<any>;
		districts: Array<any>;
		streets: Array<any>;
	};
	fields: FormlyFieldConfig[];
	price_field: FormlyFieldConfig;
	room_field: FormlyFieldConfig;
	area_field_min: FormlyFieldConfig;
	area_field_max: FormlyFieldConfig;
	land_area_field_min: FormlyFieldConfig;
	land_area_field_max: FormlyFieldConfig;
	description_field: FormlyFieldConfig;
	price_currency_id: FormlyFieldConfig;
	rent_date: FormlyFieldConfig;
	buildingfloor = [-1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25]
	housefloor = [-1, 0, 1, 2, 3, 4, 5, 6]
	rooms = [
		{ label: '1', value: 1 },
		{ label: '2', value: 2 },
		{ label: '3', value: 3 },
		{ label: '4', value: 4 },
		{ label: '5+', value: 5 }
	]
	agentSearchInput = new FormControl('');
	show_message = {
		show: false,
		text: 'No users found'
	};
	error_message = {
		show_hide: false,
		message: '',
		duration: 2000
	};
	agents: Array<User> = [];
	membership: Array<User> = [];
	files = [];
	rerquarments: any;
	code: any;
	selectedstatus: any;
	disable = false
	date = new Date();
	announcement_option_types = []
	showmepop: boolean = false
	link = "";
	sort_key = "code"
	sort_dir = "desc"
	sendLink: any;
	price_field_max;
	showCopy = false;
	onlyMy: boolean;


	constructor(
		private AnnouncementService: AnnouncementService,
		private UsersService: UsersService,
		private translateService: TranslateService,
		private requiredPropertyService: RequiredPropertiesService,
		private modalController: ModalController,
		public alertController: AlertController,
		private changeDetectorRef: ChangeDetectorRef
	)
	{ }

	ngOnInit() {
		this.agentSearchInput.valueChanges.subscribe((val) => {
			if (val.length >= 3) {
				this.UsersService.postUsersSearch(val, 2, false).subscribe((res) => {
					this.agents = res;
					this.show_message.show = false;
				});
			} else if (val.length !== 0) {
				this.show_message.show = true;
			} else {
				this.agents = [];
				this.show_message.show = false;
			}
		});

		if (this.current_required_property) {
			this.getRequirement();
			this.requiredPropertyModel = this.requiredPropertyModel.setData(this.current_required_property);
			this.edited = true;
			if (this.current_required_property['agent']) {
				this.membership = [this.current_required_property['agent']];
			}
		}
		this.requiredPropertyService.getRequiredPropertyCreate().then((res) => {
			this.property_types = this.propertyTypeModel.toOptions(res['property_types']);
			this.announcement_types = this.announcementTypeModel.toOptionsLabel2(res['announcement_types']);
			this.locations = {
				states: this.locationModel.toOptions(res['states']),
				communities: this.locationModel.toOptions(res['communities']),
				districts: this.locationModel.toOptions(res['districts']),
				streets: this.locationModel.toOptions(res['streets']),
			};
			this.statuses = res['statuses'];
			if (this.current_required_property) {
				this.selectedstatus = this.current_required_property.status_name_id;
			}
			this.translateService.lang.subscribe((lang) => {
				this.texts = this.translateService.textsData[lang].required_property_create;
				this.price_field = {
					key: 'price_min',
					type: 'floating-input',
					templateOptions: {
						label: this.texts.labels.price + ' մին',
						required: false,
						type: "number"
					}
				};
				this.price_field_max = {
					key: 'price_max',
					type: 'floating-input',
					templateOptions: {
						label: this.texts.labels.price + ' մաքս',
						required: false,
						type: "number"
					}
				};
				this.room_field = {
					key: 'rooms',
					type: 'floating-select-multiple',
					defaultValue: this.current_required_property ? this.current_required_property.rooms : [],
					templateOptions: {
						label: this.texts.labels.rooms,
						required: false,
						options: this.rooms
					},
				};
				this.area_field_min = {
					key: 'area_min',
					type: 'floating-input',
					templateOptions: {
						label: this.texts.labels.area_min,
						required: false,
						type: "number"
					}
				};
				this.area_field_max = {
					key: 'area_max',
					type: 'floating-input',
					templateOptions: {
						label: this.texts.labels.area_max,
						required: false,
						type: "number"
					}
				};
				this.land_area_field_min = {
					key: 'land_area_min',
					type: 'floating-input',
					templateOptions: {
						label: this.texts.labels.land_area_min,
						required: false,
						type: "number"
					}
				};
				this.land_area_field_max = {
					key: 'land_area_max',
					type: 'floating-input',
					templateOptions: {
						label: this.texts.labels.land_area_max,
						required: false,
						type: "number"
					}
				};
				this.price_currency_id = {
					key: 'price_currency_id',
					type: 'floating-select',
					defaultValue: this.current_required_property ? 1 : 1,
					templateOptions: {
						label: 'Արժույթ',
						required: false,
						options:this.locationModel.toCurrencies(res['currencies']) 
					}
				};
				this.rent_date = {
					key: 'rent_date',
					type: 'floating-datetime',
					defaultValue: this.current_required_property ? this.current_required_property.rent_end_date : null,
					templateOptions: {
						label: 'Վարձ․ Ամսաթիվ',
						// required: false,
					}
				};
				this.description_field = {
					fieldGroupClassName: 'required-properties-description',
					fieldGroup: [
						{
							key: 'description',
							type: 'floating-textarea',
							templateOptions: {
								label: this.texts.labels.description,
								required: false
							}
						}
					]
				};
				this.fields = [
					{
						fieldGroupClassName: 'required-property-modal-annnouncement-property-type',
						fieldGroup: [
							{
								key: 'property_type_id',
								type: 'disabled-radio',
								defaultValue: this.edited ? this.requiredPropertyModel.property_type_id : 1,
								templateOptions: {
									required: true,
									options: this.property_types,
									disabled: this.current_required_property ? true : false
								},
								hooks: {
									onInit: (field: FormlyFieldConfig) => {
										field.form
											.get('property_type_id')
											.valueChanges.pipe(
												startWith(
													this.requiredPropertyModel.property_type_id
														? this.requiredPropertyModel.property_type_id
														: 1
												)
											)
											.subscribe((property_type_id) => {
												if (property_type_id) {
													const property_type = res['property_types'].find((prop_type) => {
														return prop_type.id === property_type_id;
													});
													this.announcement_option_types = [];
													property_type.allowed_fields_required_property.forEach((field) => {
														this.announcement_option_types.push({
															key: `option${field.id}`,
															type: 'floating-select-multiple',
															defaultValue: this.requiredPropertyModel.setPivotOptionsEdit(
																field.id,
																this.requiredPropertyModel.options
															),
															templateOptions: {
																label: field.title,
																options: this.propertyTypeModel.toOptionsRequiredProperties(field),

															}
														});
													});
													let arr = [...this.fields];
													const streetSelect = {
														key: 'street',
														type: 'floating-select-multiple',
														defaultValue:
															this.edited && this.requiredPropertyModel.location_street_id
																? this.requiredPropertyModel.location_street_id
																: null,
														templateOptions: {
															label: this.texts.labels.locations[3],
															required: false,
															multiple: true,
															options: this.locations.streets
														},
														hooks: {
															onInit: (field: FormlyFieldConfig) => {
																if (field.form.get('community').value) {
																	const communities = field.form.get('community').value;
																	const templateOptions = [];
																	communities.forEach((community) => {
																		this.locationModel
																			.getChildLocs(community, this.locations.streets)
																			.forEach((option) => {
																				templateOptions.push(option);
																			});
																	});
																	field.templateOptions.options = templateOptions;
																}
																field.form.get('community').valueChanges.pipe
																	// startWith(this.requiredPropertyModel.street),
																	()
																	.subscribe((communities) => {
																		if (communities) {
																			field.form.get('street').reset();
																			const templateOptions = [];
																			communities.forEach((community) => {
																				this.locationModel
																					.getChildLocs(community, this.locations.streets)
																					.forEach((option) => {
																						templateOptions.push(option);
																					});
																			});
																			field.templateOptions.options = templateOptions;
																		}
																	});
															}
														}
													};
													if (property_type_id == 1 || property_type_id == 2) {
														arr[2] = {
															fieldGroupClassName:
																'required-properties-modal-announcement-option-types',
															fieldGroup: [
																streetSelect,
																...this.announcement_option_types,
																
															]
														};
														arr[3] = {
															fieldGroupClassName:
																'required-properties-modal-building-types-2',
															fieldGroup: [
																{
																	key: 'floor',
																	type: 'floating-select-multiple',
																	defaultValue: this.current_required_property ? this.current_required_property.floor : [],
																	templateOptions: {
																		label: this.texts.labels.floor,
																		required: false,
																		options: property_type_id == 1 ? this.returnFloors(this.buildingfloor) : this.returnFloors(this.housefloor)
																	}
																},
																this.price_field,
																this.price_field_max,
																this.room_field,
																this.area_field_min,
																this.area_field_max,
																this.price_currency_id,
																this.rent_date,
															]
														};
														arr[4] = this.description_field;
													} else {
														const area_min_field = property_type_id == 4 ? this.land_area_field_min : this.area_field_min;
														const area_max_field =  property_type_id == 4 ? this.land_area_field_max : this.area_field_max;
														arr[2] = {
															fieldGroupClassName:
																'required-properties-modal-building-types-2',
															fieldGroup: [
																streetSelect,
																this.price_field,
																this.price_field_max,
																this.price_currency_id,
																...this.announcement_option_types,
																area_min_field,
																area_max_field
															]
														};
														arr[3] = this.description_field;
														if (arr.length > 4) {
															arr.pop();
														}
														// arr.splice(3, 1);
													}
													this.fields = arr;
												}
											});
											// this.fields.push(this.description_field);
									}
								}
							},
							{
								key: 'announcement_type_id',
								type: 'disabled-radio',
								defaultValue: this.edited ? this.requiredPropertyModel.announcement_type_id : 1,
								templateOptions: {
									required: true,
									options: this.announcement_types,
									disabled: !!this.current_required_property
								}
							}
						]
					},
					{
						fieldGroupClassName: 'required-properties-modal-location-type',
						fieldGroup: [
							{
								key: 'state',
								type: 'floating-select',
								defaultValue: this.edited ? this.requiredPropertyModel.location_state_id : 1,
								templateOptions: {
									label: this.texts.labels.locations[1],
									required: true,
									options: this.locations.states
								}
							},
							{
								key: 'community',
								type: 'floating-select-multiple',
								defaultValue:
									this.edited && this.requiredPropertyModel.location_community_id
										? this.requiredPropertyModel.location_community_id
										: null,
								templateOptions: {
									label: this.texts.labels.locations[2],
									required: false,
									multiple: true,
									options: this.locations.communities
								},
								hooks: {
									onInit: (field: FormlyFieldConfig) => {
										field.form
											.get('state')
											.valueChanges.pipe(
												startWith(
													this.requiredPropertyModel.location_state_id ? this.requiredPropertyModel.location_state_id : 1
												)
											)
											.subscribe((state) => {
												field.templateOptions.options = this.locationModel.getChildLocs(
													state,
													this.locations.communities
												);
												if (
													state !== this.requiredPropertyModel.location_state_id &&
													this.requiredPropertyModel.location_state_id
												) {
													field.form.get('community').reset();
													field.form.get('street').reset();
												}
											});
									}
								}
							},
							{
								key: 'district',
								type: 'floating-select-multiple',
								defaultValue:
									this.edited && this.requiredPropertyModel.location_district_id
										? this.requiredPropertyModel.location_district_id
										: null,
								templateOptions: {
									label: this.texts.labels.locations[4],
									required: false,
									multiple: true,
									options: this.locations.districts
								},
								hooks: {
									onInit: (field: FormlyFieldConfig) => {
										if (field.form.get('community').value) {
											const communities = field.form.get('community').value;
											const templateOptions = [];
											communities.forEach((community) => {
												this.locationModel
													.getChildLocs(community, this.locations.districts)
													.forEach((option) => {
														templateOptions.push(option);
													});
											});
											field.templateOptions.options = templateOptions;
										}
										field.form.get('community').valueChanges.pipe
											// startWith(this.requiredPropertyModel.street),
											()
											.subscribe((communities) => {
												if (communities) {
													// field.form.get('street').reset();
													const templateOptions = [];
													communities.forEach((community) => {
														this.locationModel
															.getChildLocs(community, this.locations.districts)
															.forEach((option) => {
																templateOptions.push(option);
															});
													});
													field.templateOptions.options = templateOptions;
												}
											});
									}
								}
							},

							
						]
					}
				];
			});
		});
		this.changeDetectorRef.detectChanges();
	}

	closeModal() {
		let location_name = ''
		this.locations.communities.forEach(elem => {
			if (this.rerquarments && this.rerquarments.location_community_id && this.rerquarments.location_community_id.indexOf(elem.value) > -1) {
				let id = elem.value
				location_name = location_name + this.locations.communities.filter(elem => { return elem.value == id })[0].label
			}
		})
		this.locations.streets.forEach(elem => {
			if (this.rerquarments && this.rerquarments.location_street_id && this.rerquarments.location_street_id.indexOf(elem.value) > -1) {
				let id = elem.value
				location_name = location_name + '- ' + this.locations.streets.filter(elem => { return elem.value == id })[0].label + ', '
			}
		})
		if (this.rerquarments) {
			location_name.slice(1, -2)
			this.rerquarments['location_name'] = location_name
			this.rerquarments['property_type'] = {}
			this.rerquarments['property_type'].label = this.property_types[this.rerquarments.property_type_id - 1].label
		}
		this.modalController.dismiss(this.rerquarments);
	}
	returnFloors(count) {
		let arr = []
		count.forEach(element => {
			arr.push({ label: element, value: element })
		});
		return arr
	}
	saveRequirement() {
		console.log(1, this.disable)
		if (!this.disable) {
			console.log(2, this.disable)
			this.disable = true
			console.log(3, this.disable)
			let forma = Object.assign(
				this.requiredPropertyModel.postRequireDPropertyStoreTransform(this.requiredPropertyForm.value),
				{ user_id: this.user.id }
			)
			delete this.requiredPropertyForm.value.options
			if (forma['property_type_id'] === 1) {
				delete forma["option28"]
				delete forma["option27"]
				delete forma["option25"]
			} else if (forma['property_type_id'] === 2) {
				delete forma['option6']
				delete forma['option27']
				delete forma['option25']
			} else if (forma['property_type_id'] === 3) {
				delete forma['options6']
				delete forma['option28']
				delete forma['option25']
				delete forma['option9']
			} else if (forma['property_type_id'] === 4) {
				delete forma['option6']
				delete forma['option9']
				delete forma['option27']
				delete forma['option28']
			}
			if (this.current_required_property) {
				this.requiredPropertyService.postRequiredPropertyUpdate(
					Object.assign(
						this.requiredPropertyModel.postRequireDPropertyStoreTransform(forma), { user_id: this.user.id }
					), this.current_required_property.id
				).subscribe((res) => {
	
					if (res.permission_error) {
						this.UsersService.permissionReject(res.permission_error)
					}
					else {
						this.show()
						this.rerquarments = res;
						this.disable = false
					}
				});
			} else {
				this.requiredPropertyService
					.postRequiredPropertyStore(
						Object.assign(this.requiredPropertyModel.postRequireDPropertyStoreTransform(forma), { user_id: this.user.id })
					)
					.subscribe((res) => {
						if (res['permission_error']) {
							this.UsersService.permissionReject(res['permission_error'])
						} else {
							this.show()
							this.current_required_property = res;
							this.selectedstatus = 1;
							this.rerquarments = res;
							this.disable = false
						}
					});
			}
			// this.disable = false
			console.log(3, this.disable)
		}
		console.log(4, this.disable)
	}

	requiredPropertyStatusChange(status_id) {
		this.statusChanged.emit({ required_property_id: this.current_required_property.id, status_id: status_id - 1 });
	}

	async userEdit(user) {

		let sources = await this.UsersService.getUserSources().toPromise();
		const modal = await this.modalController.create({
			component: UserCreateComponent,
			componentProps: {
				userId: user.id,
				sources: sources
			}
		});
		modal.onDidDismiss().then((res) => {
			if (res.data) {
				this.user = res.data;
			}
		});
		await modal.present();
	}

	addMembership(user: User) {
		this.agentSearchInput.setValue('');
		this.requiredPropertyService
			.postRequiredPropertyAddAgent({ required_property_id: this.current_required_property.id, user_id: user.id })
			.subscribe({
				next: (res) => {
					if (res['permission_error']) { this.UsersService.permissionReject(res['permission_error']) } else {
						this.membership = [res.agent];
					}

				},
				error: (err) => {
					this.error_message.message = err.error;
					this.error_message.show_hide = true;
					setTimeout(() => {
						this.error_message.show_hide = false;
					}, this.error_message.duration);
				}
			});
	}

	removeFromMembers(user_id: number) {
		this.requiredPropertyService
			.postDeleteFromMembers({ required_property_id: this.current_required_property.id, user_id: user_id })
			.subscribe((res) => {
				this.membership = [res.agent];
			});
	}

	handleFileInput(event) {
		let files = event.target.files
		var formData = new FormData()
		formData.append('id', this.current_required_property.id + '')

		for (let key of files) {
			formData.append('files[]', key);
		}
		this.requiredPropertyService.postFiles(formData).subscribe((res: Array<any>) => {
			if (res['permission_error']) { this.UsersService.permissionReject(res['permission_error']) } else { this.files = res }
		})
	}

	ant_control(action: number, totab) {
		if (action === 2) {
			this.obj['totab'] = totab;
			this.requiredPropertyService.changeTab(this.obj).subscribe((res) => {
				if (res['permission_error']) { this.UsersService.permissionReject(res['permission_error']) } else {

					this.selected_array = [];
					this.obj = {};
					this.getRequirement();
				}
			});
		} else if (action === 1) {
			this.obj[this.current_required_property.id] = this.selected_array;
			this.requiredPropertyService.delAnn(this.obj).subscribe((res) => {
				if (res['permission_error']) { this.UsersService.permissionReject(res['permission_error']) } else {

					this.selected_array = []
					this.obj = {};
					this.getRequirement();
				}
			});
		} else {
			this.selected_array = []
			this.obj = {};
			this.addField = true;

		}
	}

	finder() {
		if (this.code && this.code.length > 2) {
			this.AnnouncementService.propertyFinder(this.code, this.current_required_property.id).subscribe((res) => {
				this.props = res;
			});
		} else {
			this.props = [];
		}
	}
	getRequirement() {
		this.requiredPropertyService.getRequiredProperty(this.current_required_property.id).subscribe((res) => {
			this.rerquarments = res;
			this.files = res.files
		});
	}

	reset() {
		this.obj = {}
		this.selected_array = []
	}

	filter(selected, search) {
		if (this.rerquarments && selected !== 0) {
			let filtredSorted = this.rerquarments.announcements.filter(
				(ann) => ann.pivot && ann.pivot.status_name_main_id === selected && (ann.code.includes(search) || ann.location.parent.label.includes(search) || ann.location.label.includes(search)));
			return filtredSorted
		} else if (this.rerquarments && selected === 0) {
			let filtredSorted = this.rerquarments.announcements.filter((ann) => !ann.pivot && (ann.code.includes(search) || ann.location.parent.label.includes(search) || ann.location.label.includes(search)));
			if (this.sort_key !== "created_at") {
				filtredSorted.sort((a, b) => {
					let A = +a[`${this.sort_key}`]
					let B = +b[`${this.sort_key}`]
					return this.sort_dir == 'asc' ? A - B : B - A;
				});
			} else {
				filtredSorted.sort((a, b) => {
					let A = a[`${this.sort_key}`].split('-').join('').slice(0, 8)
					let B = b[`${this.sort_key}`].split('-').join('').slice(0, 8)
					return this.sort_dir == 'asc' ? A - B : B - A;
				});
			}
			return filtredSorted
		}
	}

	addProp(prop) {
		this.selected = 9
		this.active = 1
		let obj = {};
		obj[this.current_required_property.id] = prop.id;
		this.requiredPropertyService.setAnn(obj).subscribe((res) => {
			res['permission_error'] ? this.UsersService.permissionReject(res['permission_error']) : this.getRequirement();
		});
	}

	check(id, checked) {
		if (!checked) {
			this.selected_array.push(id);
			this.obj[this.current_required_property.id] = this.selected_array;
		} else {
			this.selected_array.splice(this.selected_array.indexOf(id), 1);
		}
	}
	async openInNew(id) {
		const modal = await this.modalController.create({
			cssClass: 'sinple-app',
			backdropDismiss: false,
			component: SingleAntComponent,
			componentProps: { single_id: id, modal: true },
		});
		return await modal.present();
	}
	async openEvent() {
		let eventt = { start: this.date }
		const modal = await this.modalController.create({
			component: AddEventComponent,
			componentProps: {
				date: this.date,
				event: event ? event : eventt,
				reqPropId: this.current_required_property.id
			}
		});
		return await modal.present();
	}

	archiv(id) {
		this.requiredPropertyService.required_property_id = id;
		this.requiredPropertyService.status_id = 12;
		this.requiredPropertyService.createStatusChange().subscribe((res) => {
			res['permission_error'] ? this.UsersService.permissionReject(res['permission_error']) :
				this.modalController.dismiss();
		});
	}

	sendIds() {
		this.requiredPropertyService.sendIDs(this.selected_array).subscribe(res => {
			this.sendLink = res			// this.presentToast(res)
		})
	}


	copy(val: string) {
		let selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = val;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);
	}


	show() {
		this.showmepop = true
		setTimeout(() => {
			this.showmepop = false
		}, 2000)
	}
	deletePic(id) {
		this.requiredPropertyService.deletePic(id).subscribe(res => {
			this.getRequirement()
		})
	}

	downloadExsel() {
		this.requiredPropertyService.downloadExcel(this.rerquarments.id).subscribe(
			(url: string) => {
				url['permission_error'] ? this.UsersService.permissionReject(url['permission_error']) : window.location.href = url
			})

	}

}
