import {enableProdMode, NgModule} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {JwtModule} from '@auth0/angular-jwt';
import {DragulaModule} from 'ng2-dragula';
import {NgxSmartModalModule} from 'ngx-smart-modal';
import {ReactiveFormsModule} from '@angular/forms';
import {FormlyModule} from '@ngx-formly/core';
import {FormlyIonicModule} from '@ngx-formly/ionic';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';

import {FormlyFieldIonInput} from './formly/formly-field-ion-input';
import {FormlyFieldIonTextarea} from './formly/formly-field-ion-textarea';
import {FormlyFieldIonSelect} from './formly/formly-field-ion-select';
import {FormlyFieldIonSelectMultiple} from './formly/formly-field-ion-select-multiple';
import {FormlyFieldIonRadio} from './formly/formly-field-ion-radio';
import {FormlyFieldIonDateTime} from './formly/formly-field-ion-datetime';
import {NgxPaginationModule} from 'ngx-pagination';
import {FroalaEditorModule} from 'angular-froala-wysiwyg';


export function _tokenGetter() {
    return localStorage.getItem('token');
}

@NgModule({
    declarations: [AppComponent, FormlyFieldIonInput, FormlyFieldIonTextarea, FormlyFieldIonSelect, FormlyFieldIonSelectMultiple, FormlyFieldIonRadio, FormlyFieldIonDateTime],
    entryComponents: [],
    imports: [BrowserModule,
        HttpClientModule,
        LeafletModule.forRoot(),
        JwtModule.forRoot({
            config: {
                tokenGetter: _tokenGetter,
            }
        }),
        DragulaModule.forRoot(),
        NgxSmartModalModule.forRoot(),
        IonicModule.forRoot(),
        AppRoutingModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        NgxPaginationModule,
        FormlyModule.forRoot({
            types: [
                {name: 'floating-datetime', component: FormlyFieldIonDateTime},
                {name: 'floating-input', component: FormlyFieldIonInput},
                {name: 'floating-textarea', component: FormlyFieldIonTextarea},
                {name: 'floating-select', component: FormlyFieldIonSelect},
                {name: 'floating-select-multiple', component: FormlyFieldIonSelectMultiple},
                {name: 'disabled-radio', component: FormlyFieldIonRadio}
            ]
        }),
        FormlyIonicModule,
        FroalaEditorModule.forRoot(),
    ],
    providers: [
        Title,
        StatusBar,
        SplashScreen,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

enableProdMode();
