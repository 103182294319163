import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  pagetitle ="Calendar"
  lang = new BehaviorSubject<string>('hy');

  constructor() { }

  textsData = {
    hy: {
      login: {
        email: 'Էլ․ հասցե',
        password: 'Գաղտնաբառ',
        signin: 'Մուտք'
      },
      home: {

      },
      sidemenu: {
        1: ['Ավելացնել Հաճախորդ', 'Բոլոր Հաճախորդները', 'Զանգեր'],
        // 2: ['Բնակարան', 'Առանձնատուն', 'Հող', 'Կոմերցիոն', 'Ավելացնել նոր'],
        3: ['Ավելացնել Պահանջարկ', 'Բոլոր Պահանջարկը'],
        4: ['Ավելացնել աշխատակից', 'Բոլոր աշխատակիցները'],
        5: ['Պահանջարկներ', 'Գույքեր'],
        6: [' Կարգավորում', ' Կարգավորում'],
        7: ['Կոնտակտ էջ', 'Վերնագիր', 'Ստորագիր', 'Գլխավոր էջ']
      },
      top_tabs: {
        order: 'Պահանջարկ',
        estate: 'Անշարժ գույք',
        statistics: 'Վիճակագրություն',
        contracts: 'Պայմանագրեր',
        selecteds: 'Ընտրվածներ'
      },
      users_create: {
        name: 'Անուն',
        surname: 'Ազգանուն',
        phone: 'Հեռախոսահամար',
        email: 'Էլ․ փոստ',
        about: 'Որտեղից եք իմացել',
        description: 'Մեկնաբանություն',
        save: 'Պահպանել'
      },
      required_property_create: {
        save: 'Պահպանել',
        subscribe: 'Կցել գործակալ',
        file: 'Կցել ֆայլ',
        propertyType: 'Գույքի տեսակ',
        announcementType: 'Կարգավիճակ',
        labels: {
          locations: {
            1: 'Մարզ',
            2: 'Համայնք',
            3: 'Փողոց',
            4: 'Թաղամաս',

          },
          floor: 'Հարկ',
          price: 'Գին',
          rooms: 'Սենյակ',
          area_min: 'Մին շին․ մակերես',
          area_max: 'Մաքս շին․ մակերես',
          land_area_min: 'Մին հող մակերես',
          land_area_max: 'Մաքս հող մակերես',
          description: 'Մեկնաբանություն'
        }
      },
      announcement_search: {
        0: {
          checkboxes: [
            {
              label: 'Էքսկլյուզիվ գույք'
            }
          ]
        }
      }
    }
  };
}
