import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UsersService } from '../../services/users.service';
import { ModalController, AlertController } from '@ionic/angular';
import { AuthService } from '../../services/auth.service'

@Component({
	selector: 'app-add-staff',
	templateUrl: './add-staff.component.html',
	styleUrls: ['./add-staff.component.scss']
})
export class AddStaffComponent implements OnInit {
	@Input() data;
	user
	userCreateForm: FormGroup;
	user_picture = 'assets/img/avatar.png';
	fileImage;
	img;
	positiones = [];
	positionFromUser = 8;
	userRole =0
	prop: boolean;
	roles: Array<any>;
	constructor(
		private AuthService:AuthService,
		public alertController: AlertController,
		public modalController: ModalController,
		// private changeDetectorRef: ChangeDetectorRef,
		public ModalController: ModalController,
		private UsersService: UsersService,
		private fb: FormBuilder
	) { }

	ngOnInit() {
		this.getPositions();
		if (this.data) {
			this.userCreateForm = this.fb.group({
				id: [this.data.id],
				name: [this.data.name, [Validators.required, Validators.minLength(3), Validators.maxLength(12)]],
				surname: [
					this.data.surname,
					[Validators.required, Validators.minLength(3), Validators.maxLength(16)]
				],
				position_id: ['', Validators.required],
				phone: [
					this.data.contact_types?.length >0  ? this.data.contact_types[0].phone : 0,
					[Validators.required, Validators.minLength(8), Validators.maxLength(8)]
				],
				birthday: [this.data.birthday, Validators.required],
				email: [this.data.email, [Validators.email, Validators.required]],
				address: [this.data.address],
				passport: [this.data.passport],
				role: ['',[ Validators.required]],
				password: [],
				status: [this.data.status],
				description: [this.data.description],
				user_type: [2]
			});
			this.user_picture = this.data.image_url ? this.data.image_url : this.user_picture;
		} else {
			this.userCreateForm = this.fb.group({
				name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(12)]],
				surname: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(16)]],
				position_id: ['', Validators.required],
				role: [2,[ Validators.required]],
				phone: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(8)]],
				birthday: ['', Validators.required],
				email: ['', [Validators.email, Validators.required]],
				address: [''],
				passport: [''],
				password: ['', Validators.required],
				status: [''],
				description: [''],
				user_type: [2]
			});
		}
	}

	ionViewWillEnter() {
		this.AuthService.getCurrentUser().subscribe(res => {
				this.user = res;
			if (this.data.hasOwnProperty('id')) {
				console.log(res)
				this.userCreateForm.get('position_id').setValue(res.position_id);
				this.userCreateForm.get('role').setValue(res.roles?.length ? res.roles[0].id: null);
			}
		})
	}

		close() {
		this.modalController.dismiss();
	}


	create() {
		if (this.userCreateForm.status == 'VALID') {
			this.UsersService.postStaffCreate(this.userCreateForm.value).subscribe(
				(res) => {
					res['permission_error'] ? this.UsersService.permissionReject(res['permission_error']):
					this.ModalController.dismiss();
				},
				(err) => {

				}
			);
		} else {
			this.prop = true;
		}
	}

	onUpload(event) {
		this.fileImage = <File>event.target.files[0];
		const reader = new FileReader();
		reader.readAsDataURL(this.fileImage);
		reader.onload = (e) => {
			this.img = e.target['result'];
			this.UsersService.saveImage(this.userCreateForm.get('id').value, this.img).subscribe((res) => {
				this.user_picture = this.img;
			});
		};
	}

	getPositions() {
		this.UsersService.getPositiones().subscribe((res) => {
			this.positiones = res['positions'];
			this.roles = res['roles'];
			if (this.data) {
				this.positionFromUser = this.data.position_id;
				if (this.data.hasOwnProperty('roles')) {
					this.userRole = this.data.roles['0'] ?  this.data.roles['0'].id : 0
				}
			} else {
				this.positionFromUser = 8;
				this.userRole = 2
			}
		});
	}

	deletepic(id) {
		this.UsersService.deleteUserPicture(id).subscribe(res => {
			this.user_picture = 'assets/img/avatar.png'
		})

	}
}
