import { Component, OnInit, Input, } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { TranslateService } from 'src/app/services/translate.service';
import { UsersService } from 'src/app/services/users.service';
import { ModalController, ToastController, AlertController } from '@ionic/angular';
import { RequiredPropertiesComponent } from '../required-properties/required-properties.component';
import { AuthService } from '../../services/auth.service'

// import { AllowedCharactersDirective }from '../../allowed-characters.directive'

@Component({
	selector: 'app-user-create',
	templateUrl: './user-create.component.html',
	styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent implements OnInit {
	/* tslint:disable */

	@Input() userId: number;
	@Input() sources: Array<{ id: number; title: string }>;
	user: Object
	userCreateForm: FormGroup;
	phones: FormArray;
	texts: any;
	prop: boolean;
	res: Object
	constructor(
		private AuthService: AuthService,
		public alertController: AlertController,
		private fb: FormBuilder,
		private translate: TranslateService,
		private usersService: UsersService,
		public modalController: ModalController,
		public ToastController: ToastController
	) { }

	ngOnInit() {
		this.AuthService.getCurrentUser().subscribe(res => { this.user = res })

		this.translate.lang.subscribe((lang) => {
			this.texts = this.translate.textsData[lang].users_create;
		});
		this.userCreateForm = this.fb.group({
			id: [],
			name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(12)]],
			surname: ['', [Validators.minLength(3), Validators.maxLength(16)]],
			phones: this.fb.array([this.createPhoneForm()]),
			birthday: [''],
			email: ['', [Validators.email]],
			source_id: [1],
			description: [''],
			user_type: [1]
		});

		if (this.userId) {
			this.phones = this.userCreateForm.get('phones') as FormArray;
			this.phones.removeAt(0);
			this.usersService.getSingleUser(this.userId).subscribe((res) => {
				if (res['permission_error']) { this.usersService.permissionReject(res['permission_error']); this.modalController.dismiss() } else {
					this.res = res
					this.userCreateForm.get('name').setValue(res['name']);
					this.userCreateForm.get('surname').setValue(res['surname']);

					res['contact_types'].forEach((element) => {
						let phones = this.userCreateForm.get('phones') as FormArray;
						phones.push(
							this.fb.group({
								phone: [element.phone, [Validators.required, Validators.maxLength(15), Validators.minLength(7)]],
								whatsapp: element.whatsapp,
								viber: element.viber
							})
						);
					});
					if (res['contact_types'].length == 0) {
						let phones = this.userCreateForm.get('phones') as FormArray;
						phones.push(
							this.fb.group({
								phone: ['', [Validators.required, Validators.maxLength(15), Validators.minLength(7)]],
								whatsapp: false,
								viber: false
							})
						);
					}
					this.userCreateForm.get('id').setValue(res['id']);
					this.userCreateForm.get('birthday').setValue(res['birthday']);
					this.userCreateForm.get('email').setValue(res['email']);
					this.userCreateForm.get('source_id').setValue(res['source_id']);
					this.userCreateForm.get('description').setValue(res['description']);
				}
			});
		}
	}

	createPhoneForm() {
		return this.fb.group({
			phone: ['', [Validators.required, Validators.maxLength(15), Validators.minLength(7)]],
			whatsapp: false,
			viber: false
		});
	}

	addPhoneForm() {
		this.phones = this.userCreateForm.get('phones') as FormArray;
		this.phones.push(this.createPhoneForm());
	}

	deletePhoneForm(ind: number) {
		this.phones = this.userCreateForm.get('phones') as FormArray;
		this.phones.removeAt(ind);
	}
	closeModal() {
		this.modalController.dismiss(false);
	}
	createUser() {
		console.log(this.userCreateForm)
		if (!this.userCreateForm.invalid) {
			this.usersService.postUserCreate(this.userCreateForm.value).subscribe((res) => {
				if (res) {
					res.permission_error ? this.usersService.permissionReject(res.permission_error) :
						this.modalController.dismiss(res);
				}
			},
				({error}) => {
					Object.keys((error.errors)).forEach((key) => {
						this.presentToast(error.errors[key])
					})

				});
		} else {
			this.prop = true;
		}
	}

	async presentModal() {
		const modal = await this.modalController.create({
			backdropDismiss: false,
			component: RequiredPropertiesComponent
		});
		return await modal.present();
	}
	async presentToast(message) {
		const toast = await this.ToastController.create({
			color: 'danger',
			position: "middle",
			message: message,
			duration: 2000
		});
		toast.present();
	}

}
