import { Component } from '@angular/core';
import { Platform, ModalController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthService } from './services/auth.service'
import { UsersService } from './services/users.service';
import { UserCreateComponent } from './home/user-create/user-create.component';
import { Router } from '@angular/router';
import { AddStaffComponent } from './pages/add-staff/add-staff.component';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public appPages = [
    // {
    //   title: 'Calendar',
    //   icon: 'assets/icon/users.png'
    // },
    {
      title: 'Գլխավոր',
      icon: 'assets/icon/home.png',
      url: 'home'
    },
    {
      title: 'Հաճախորդներ ',
      icon: 'assets/icon/users.png',
      children: [
        {
          title: 'Ավելացնել Հաճախորդ',
          action: 'add-client'
        },
        {
          title: 'Բոլոր Հաճախորդները',
          link: 'home/clients'
        },
      ]
    },
    {
      title: ' Պահանջարկ ',
      icon: 'assets/icon/bid.png',

      children: [
        {
          title: 'Ավելացնել պահանջարկ',
          link: 'home/application'
        },
        {
          title: 'Բոլոր պահանջարկները',
          link: 'home/allapps'
        }

      ]
    },
    {
      title: 'Անշարժ գույք  ',
      icon: 'assets/icon/home.png',

      children: [
        {
          title: 'Ավելացնել Գույք ',
          link: 'home/properties'
        },
        {
          title: 'Բոլոր գույքերը',
          link: 'home/search/0'
        },
        {
          title: 'Բնակարան',
          link: 'home/search/1'
        },
        {
          title: 'Առանձնատուն',
          link: 'home/search/2'
        },
        {
          title: 'Կոմերցիոն',
          link: 'home/search/3'
        },
        {
          title: 'Հող',
          link: 'home/search/4'
        }
      ]
    },
    {
      title: 'Անձնակազմ',
      icon: 'assets/icon/staff.png',

      children: [
        {
          title: 'Ավելացնել աշխատակից',
          action: 'add-staff'
        },
        {
          title: 'Բոլոր աշխատակիցները',
          link: 'home/staff'
        }

      ]
    },
    {
      title: 'Արխիվ',
      icon: 'assets/icon/archive.png',

      children: [
        {
          title: 'Պահանջարկներ',
          link: 'home/archived-apps'
        },
        {
          title: 'Գույքեր',
          link: 'home/archived-prop'
        }

      ]
    },
  ];
  constructor(
    private AuthService: AuthService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private modalCtrl: ModalController,
    private usersService: UsersService,
    private router: Router
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.AuthService.getCurrentUser().subscribe(res => {
      this.AuthService.User = res
    })
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  route(sub, p) {
    if (sub.link) {
      this.router.navigate([`${sub.link}`]);
    } else if (sub.action) {
      switch (sub.action) {
        case 'add-client':
        this.addClient();
          break;
        case 'add-staff':
          this.addStaff();
          break;
        case 'add-required-property':
        default:
          break;
      }
    }
    p.open = false;
  }

  async addClient() {
    let sources = await this.usersService.getUserSources().toPromise();
    const modal = await this.modalCtrl.create({
      component: UserCreateComponent,
      cssClass: 'add-client-modal',
      backdropDismiss: false,
      componentProps: {
        sources: sources
      }
    });
    return await modal.present();
  }

  async addStaff() {
		const modal = await this.modalCtrl.create({
			cssClass: 'add-staff',
			backdropDismiss: false,
			component: AddStaffComponent
		});
		return await modal.present();
  }

  logout() {
    localStorage.removeItem('token');
    this.router.navigate(['login']);
  }
}
