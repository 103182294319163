import { Component, OnInit, Input } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { UsersService } from '../../services/users.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';

@Component({
	selector: 'app-add-event',
	templateUrl: './add-event.component.html',
	styleUrls: ['./add-event.component.scss']
})
export class AddEventComponent implements OnInit {
	/* tslint:disable */
	@Input() date
	@Input() event
	@Input() reqPropId

	addEventForm: FormGroup;
	prop: boolean;
	color = 'red'
	d = new Date();
	today = this.d.getFullYear() + '-'
		+ ('0' + (this.d.getMonth() + 1)).slice(-2)
		+ '-' + ('0' + this.d.getDate()).slice(-2)
		+ 'T' + ('0' + this.d.getHours()).slice(-2)
		+ ':' + ('0' + (this.d.getMinutes() + 1)).slice(-2)
		+ 'Z';
	agents;
	membership = [];

	constructor(
		private UsersService: UsersService,
		private modalController: ModalController,
		private fb: FormBuilder,
		public alertController: AlertController
	) { }

	show_message = {
		show: false,
		text: 'No users found'
	};
	error_message = {
		show_hide: false,
		message: '',
		duration: 2000
	};
	agentSearchInput = new FormControl('');

	format(d) {
		let date = new Date(d)
		return date.getFullYear() + '-'
			+ ('0' + (date.getMonth() + 1)).slice(-2)
			+ '-' + ('0' + date.getDate()).slice(-2)
			+ 'T' + ('0' + date.getHours()).slice(-2)
			+ ':' + ('0' + (date.getMinutes() + 1)).slice(-2)
			+ 'Z';
	}

	ngOnInit() {
		if (this.event.title) {
			this.color = this.event.color.name
			this.addEventForm = this.fb.group({
				id: this.event.id,
				title: [this.event.title, [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
				start_datetime: [this.event.start, [Validators.required]],
				end_datetime: [this.event.end, [Validators.required]],
				location: [this.event.location],
				color: this.event.color.name,
				description: this.event.description
			});
		} else {
			if (this.date > this.d) {
				this.event['start'] = this.date
				this.event['end'] = this.date
			} else {
				this.event['start'] = this.d
				this.event['end'] = this.d
			}
			this.addEventForm = this.fb.group({
				title: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
				start_datetime: [this.event['start'], [Validators.required]],
				end_datetime: [this.event['start'], [Validators.required]],
				location: [''],
				color: [this.color],
				description: ['']
			});
			// this.addEventForm.get("start_datetime").setValue(this.datetransform(new Date(this.d)))
			// this.addEventForm.get("end_datetime").setValue(this.datetransform(new Date(this.d)))
		}
		this.agentSearchInput.valueChanges.subscribe((val) => {
			if (val.length >= 3) {
				this.UsersService.postUsersSearch(val, 2,false).subscribe((res) => {
					this.agents = res;
					this.show_message.show = false;
				});
			} else if (val.length !== 0) {
				this.show_message.show = true;
			} else {
				this.agents = [];
				this.show_message.show = false;
			}
		});
		if (this.reqPropId) {
			this.addEventForm.get('description').setValue('Հայտ համար ' + this.reqPropId)
		}

	}
	datetransform(date) {
		return new Date(date);
	}
	closeModal() {
		this.modalController.dismiss();
	}

	dtaesetter(date) {
		console.log(new Date(date))
		return new Date(date)
	}

	addMembership(user) {
		this.agentSearchInput.setValue('');
		this.membership.push(user);
	}
	removeFromMembers(user_id: number) {
		this.membership.splice(0, 1);
	}

	addEvent() {
		if (this.addEventForm.status == 'VALID') {
			this.UsersService.postNewEvent(this.addEventForm.value).subscribe(
				(res) => {
					res['permission_error'] ? this.UsersService.permissionReject(res['permission_error']): 	this.modalController.dismiss(this.addEventForm.value);
				},
				(err) => {
					alert(err);
				}
			);
		} else {
			this.prop = true;
		}
	}
}
