/* tslint:disable */
export class LocationModel {
    id: number;
    label: string;
    location_type_id: number;
    parent_id: number;
    title?: string
    constructor() { }

    setData(data: any) {
        Object.assign(this, data);
        return this;
    }

    toOptions(items: this[]) {
        const arr = [];
        items.forEach(item => {
            arr.push({ value: item.id, label: item.label, parent_id: item.parent_id });
        });
        return arr;
    }
    toCurrencies(items: this[]) {
        const arr = [];
        items.forEach(item => {
            arr.push({ value: item.id, label: item.title, parent_id: item.parent_id });
        });
        return arr;
    }

    getChildLocs(locId: number, childs: this[]) {
        return childs.filter(child => {
            return locId === child.parent_id;
        });
    }
}