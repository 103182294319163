/* tslint:disable */
export class PropertyTypeModel {
    id: number;
    label: string;
    allowed_fields_announcement: Array<any>;
    allowed_fields_required_property: Array<any>;

    constructor() { }

    setData(data: any) {
        Object.assign(this, data);
        return this;
    }

    toOptions(items: this[]) {
        const arr = [];
        items.forEach(item => {
            arr.push({value: item.id, label: item.label});
        });
        if (arr) {
            return arr;
        } else {
            return null;
        }
    }

    toOptionsRequiredProperties(item: any) {
        const arr = [];
        item.options.forEach(field => {
            arr.push({value: field.id, label: field.title});
        });
        return arr;
    }
}
