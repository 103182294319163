import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
 selector: 'formly-field-input',
 template: `
    <ion-item>
        <ion-label position="floating">{{ field.templateOptions.label }}</ion-label>
        <ion-input [type]="field.templateOptions.type" [formControl]="formControl" [formlyAttributes]="field"></ion-input>
    </ion-item>
 `,
})
export class FormlyFieldIonInput extends FieldType {

}
