import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
 selector: 'formly-field-datetime',
 template: `
    <ion-item>
        <ion-label  position="floating">{{ field.templateOptions.label }} </ion-label>
        <ion-datetime [max]="2050-01-01" [formControl]="formControl"   [formlyAttributes]="field"></ion-datetime>
    </ion-item>
 `,
})
export class FormlyFieldIonDateTime extends FieldType {

}
