import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Router } from '@angular/router'

const API_URL = environment.API_URL;


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  User

  constructor(
    private http: HttpClient,
    private Router: Router
  ) { }

  headers() {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${window.localStorage.getItem('token')}`
    });
    return headers;
  }

  signIn(obj: object): Observable<any> {
    return this.http.post(`${window.localStorage.getItem('servname') + API_URL}/login`, obj);
  }

  getCurrentUser(): Observable<any> {
    const headers = this.headers();
    return this.http.get(`${window.localStorage.getItem('servname') + API_URL}/auth/user`, { headers });
  }

  getLoggedUser(): Observable<any> {
    const headers = this.headers();
    return this.http.get(`${window.localStorage.getItem('servname') + API_URL}/users/profile`, { headers });
  }

  isAuthenticated(): boolean {
    return !!localStorage.getItem('token')
  }


  canActivate(): boolean {
    if (!this.isAuthenticated()) {
      this.Router.navigate(['login']);
      return false;
    }
    return true;
  }

  logOut() {
    const headers = this.headers();
    return this.http.get(`${window.localStorage.getItem('servname') + API_URL}/logout`, { headers });
  }
}
