import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { TranslateService } from './translate.service';
import { AnnouncementType } from '../interfaces/announcement/announcement-type';
import { AnnouncementOptionType } from '../interfaces/announcement/announcement-option-type';
import { AnnouncementOption } from '../interfaces/announcement/announcement-option';

const API_URL = environment.API_URL;

@Injectable({
	providedIn: 'root'
})
export class AnnouncementService {
	/* tslint:disable */
	filterdata
	reset = false
	link = {}
	search
	lang: string;
	property_types_config = {
		1: {
			checkboxes: [
				{
					label: 'Էքսկլյուզիվ գույք',
					key: 'isExclusive'
				},
				{
					label: 'Բացի էքսկլյուզիվ գույքերից',
					key: 'notExclusive'
				},
				{
					label: 'Փոխարինելի',
					key: 'replaceable'
				},
				{
					label: 'Կառուցապատողից',
					key: 'from_developer'
				}
			]
		}
	};

	announcement_types: AnnouncementType[];
	announcement_option_types: AnnouncementOptionType[];
	announcement_options: AnnouncementOption[];
	locations: Array<any>;

	constructor(private http: HttpClient, private translate: TranslateService) {
		this.translate.lang.subscribe((lang) => {
			this.lang = lang;
		});
	}

	headers() {
		return new HttpHeaders({
			Authorization: `Bearer ${window.localStorage.getItem('token')}`
		});
	}
	paginationFilter(filterData, url) {
		const headers = this.headers();
		return this.http.post(url, { filterData }, { headers });
	}
	paginationAnn(url,sort_keys) {
		const headers = this.headers();
		return this.http.post(url, sort_keys, { headers });
	}
	pagination(url) {
		const headers = this.headers();
		return this.http.post(url, { headers });
	}
	paginationStaff(url, value?: string, paginate?: boolean) {
		const headers = this.headers();
		return this.http.post(url, {value, paginate},{ headers });
	}
	paginationStaffPost(page, val, archive, id) {
		const headers = this.headers();
		return this.http.post(`${window.localStorage.getItem('servname') + API_URL}/announcement/find?page=${page}`, {val, archive,id}, { headers });
	}
	paginationStaffGet(url, value?: string, paginate?: boolean) {
		const headers = this.headers();
		return this.http.get(url, { headers });
	}


	getAnnouncement(sort_keys) {
		const headers = this.headers();
		return this.http.post(`${window.localStorage.getItem('servname') + API_URL}/announcement/${this.lang}/list`,  sort_keys , { headers });
	}



	getAnnouncementArchive() {
		const headers = this.headers();
		return this.http.get(`${window.localStorage.getItem('servname') + API_URL}/announcement/${this.lang}/archive/`, { headers });
	}
	getAllPropertyTypes(): Observable<any> {
		const headers = this.headers();
		return this.http.get(`${window.localStorage.getItem('servname') + API_URL}/property_types/${this.lang}/index`, { headers });
	}

	getSingleAnnT(id): Observable<any> {
		const headers = this.headers();
		return this.http.get(`${window.localStorage.getItem('servname') + API_URL}/announcement/${this.lang}/show/` + id, { headers });
	}

	getAnnouncementDetails(): Promise<any> {
		const headers = this.headers();
		return new Promise((resolve) => {
			this.http.get(`${window.localStorage.getItem('servname') + API_URL}/announcement/${this.lang}/create`, { headers }).subscribe((res) => {
				if (res) {
					this.announcement_types = res['announcement_types'];
					this.announcement_option_types = res['announcement_option_types'];
					this.announcement_options = res['announcement_options'];
					resolve(true);
				}
			});
		});
	}

	propertyFinder(code, id) {
		const headers = this.headers();
		return this.http.post(`${window.localStorage.getItem('servname') + API_URL}/announcement/codesearch`, { code: code, id: id }, { headers });
	}

	searchAnn(val) {
		const headers = this.headers();
		return this.http.post(`${window.localStorage.getItem('servname') + API_URL}/announcement/find?page=1`,  val , { headers });
	}
	getFormData() {
		const headers = this.headers();
		return this.http.get(`${window.localStorage.getItem('servname') + API_URL}/announcement/create/`, { headers });
	}
	getlocationsForSearch() {
		const headers = this.headers();
		this.http.get(`${window.localStorage.getItem('servname') + API_URL}/announcement/create/`, { headers }).subscribe((res: Array<any>) => { this.locations = res['locations'] });
	}
	getLocations(idss): Observable<any> {
		const headers = this.headers();
		let ids = [];
		ids.push(idss);
		return this.http.post(`${window.localStorage.getItem('servname') + API_URL}/location/childs/`, { ids }, { headers });
	}
	getLocationByParent(params): Observable<any> {
		const headers = this.headers();
		return this.http.post(`${window.localStorage.getItem('servname') + API_URL}/location/searchbyparent/`, { params }, { headers });
	}
	getFilters(id): Observable<any> {
		const headers = this.headers();
		return this.http.get(`${window.localStorage.getItem('servname') + API_URL}/announcement/filterdata/` + id, { headers });
	}

	getFiltredApts(filterData,sortKeys) {
		const headers = this.headers();
		return this.http.post(`${window.localStorage.getItem('servname') + API_URL}/announcement/filter/`,  {sortKeys, filterData }, { headers });
	}

	save_application(form) {
		const headers = this.headers();
		return this.http.post(`${window.localStorage.getItem('servname') + API_URL}/announcement/save/`, { ...form }, { headers });
	}

	addToWish(id) {
		const headers = this.headers();
		return this.http.get(`${window.localStorage.getItem('servname') + API_URL}/users/wishlist/add/` + id, { headers });
	}
	remFromWish(id) {
		const headers = this.headers();
		return this.http.get(`${window.localStorage.getItem('servname') + API_URL}/users/wishlist/remove/` + id, { headers });
	}
	getWishList() {
		const headers = this.headers();
		return this.http.get(`${window.localStorage.getItem('servname') + API_URL}/users/wishlist`, { headers });
	}
	getLastAnts() {
		const headers = this.headers();
		return this.http.get(`${window.localStorage.getItem('servname') + API_URL}/tasks/lists/`, { headers });
	}
	deleteImage(id) {
		const headers = this.headers();
		return this.http.get(`${window.localStorage.getItem('servname') + API_URL}/announcement/image/delete/` + id, { headers });
	}
	downloadExcel(filterData) {
		const headers = this.headers();
		return this.http.post(`${window.localStorage.getItem('servname') + API_URL}/announcement/toexcel`,{filterData} , { headers });
	}

}
