import { Component, OnInit, Input } from '@angular/core';
import { ImageViewerComponent } from '../image-viewer/image-viewer.component';
import { ModalController, AlertController } from '@ionic/angular';
import { AnnouncementService } from '../../services/announcement.service';
import { UsersService } from '../../services/users.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import * as L from 'leaflet';

@Component({
	selector: 'app-single-ant',
	templateUrl: './single-ant.component.html',
	styleUrls: ['./single-ant.component.scss']
})
export class SingleAntComponent implements OnInit {
	@Input() single_id: number;
	@Input() modal;
	haf;
	i;
	map: any;
	myMarker: L.Marker<any>;
	myIcon: any;
	id: number;
	slide = 10000;
	announcement;
	phone_numbers = [];
	constructor(
		public location: Location,
		public modalController: ModalController,
		private AnnouncementService: AnnouncementService,
		private UsersService: UsersService,
		public ActivatedRoute: ActivatedRoute,
		public alertController: AlertController
	) { }

	ngOnInit() {

	}

	ionViewWillEnter() {
		this.ActivatedRoute.params.subscribe((params) => (this.id = params.id));
		let id = this.single_id ? this.single_id : this.id;
		this.AnnouncementService.getSingleAnnT(id).subscribe((res) => {
			if (res.permission_error) {
				this.UsersService.permissionReject(res.permission_error);
				this.location.back()
			} else {
				this.announcement = res;
				if (this.announcement.seller && this.announcement.seller.contact_types.length > 0) {
					this.announcement.seller.contact_types.forEach(contact_type => {
						if (contact_type.pivot.contact_type_id === 1) {
							// this.phone_numbers += `${contact_type.pivot.value}, `;
							this.phone_numbers.push(contact_type.pivot.value);
						}
					});

					// if (this.phone_numbers !== '') {
					// 	this.phone_numbers = this.phone_numbers.replace(', ', '');
					// }
				}
				console.log(this.announcement)
				if (this.map) { this.map.remove() }
				setTimeout(() => {

					this.drowMap();
				}, 1000)

			}

		});
	}
	ionViewWillLeave() {
		this.map.remove()
	}

	heart(id, count) {
		if (count == 0) {
			this.AnnouncementService.addToWish(id).subscribe((res) => {
			});
		} else {
			this.AnnouncementService.remFromWish(id).subscribe((res) => {
			});
		}
	}

	drowMap() {
		this.myIcon = L.icon({
			iconUrl: 'assets/icon/pin.png',
			iconSize: [40, 40],
			iconAnchor: [20, 40],
			popupAnchor: [-3, -76]
		});

		this.map = L.map('map', {
			attributionControl: false,
			center: this.announcement.coords ? this.announcement.coords.split(',') : [40.185969, 44.5240079],
			minZoom: 10,
			maxZoom: 18,
			zoom: 14
		});

		L.tileLayer('http://maps.webapricot.am/osm_tiles/{z}/{x}/{y}.png', {}).addTo(this.map);
		if (this.announcement.coords) {
			this.myMarker = L.marker(this.announcement.coords.split(','), {
				icon: this.myIcon
			})
				.addTo(this.map)
				.bindPopup(
					this.announcement.location.label + '. ' + this.announcement.building + '/' + this.announcement.apartment
				);
		}
	}

	async presentModal(image) {
		const modal = await this.modalController.create({
			component: ImageViewerComponent,
			cssClass: 'my-custom-modal-css',
			componentProps: {
				data: image
			}
		});
		return await modal.present();
	}
	closeModal() {
		this.modalController.dismiss();
	}
	calcSquer(a, b) {
		if (a && b) { return Math.floor(+a / +b) } else return 0
	}
}
