import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastController } from '@ionic/angular';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { User } from '../interfaces/users/user';

const API_URL = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  /* tslint:disable */

  constructor(private http: HttpClient,
    private toastController: ToastController
  ) { }

  tasks = []
  headers() {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${window.localStorage.getItem('token')}`
    });
    return headers;
  }

  postUsersSearch(val: string, user_type_id: number, paginate?: boolean): Observable<Array<User>> {
    const headers = this.headers();
    return this.http.post<Array<User>>(`${window.localStorage.getItem('servname') + API_URL}/users/search`, { value: val, user_type_id: user_type_id, paginate: paginate }, { headers });
  }

  postUserCreate(obj: any): Observable<any> {
    const headers = this.headers();
    return this.http.post(`${window.localStorage.getItem('servname') + API_URL}/users/customer/save`, obj, { headers });
  }
  postStaffCreate(obj: any): Observable<any> {
    const headers = this.headers();
    return this.http.post(`${window.localStorage.getItem('servname') + API_URL}/users/staff/save`, obj, { headers });
  }

  getUserSources(): Observable<any> {
    const headers = this.headers();
    return this.http.get(`${window.localStorage.getItem('servname') + API_URL}/users/create`, { headers });
  }

  getPositiones(): Observable<any> {
    const headers = this.headers();
    return this.http.get(`${window.localStorage.getItem('servname') + API_URL}/users/positions`, { headers });
  }

  getSingleUser(id) {
    const headers = this.headers();
    return this.http.get(`${window.localStorage.getItem('servname') + API_URL}/users/edit/` + id, { headers });
  }
  getUserList(id, filterName?) {
    const headers = this.headers();
    return this.http.get(`${window.localStorage.getItem('servname') + API_URL}/users/list/` + id, { headers , params: filterName});
  }
  getUserListFiltered(params) {
    const headers = this.headers();
    return this.http.post(`${window.localStorage.getItem('servname') + API_URL}/users-filter`, {},{ headers , params});
  }
  saveImage(id, img) {
    const headers = this.headers();
    return this.http.post(`${window.localStorage.getItem('servname') + API_URL}/users/saveimage`, { id, img }, { headers });
  }
  statusChange(id) {
    const headers = this.headers();
    return this.http.get(`${window.localStorage.getItem('servname') + API_URL}/users/changestatus/` + id, { headers });
  }
  getNewEvent() {
    const headers = this.headers();
    return this.http.get(`${window.localStorage.getItem('servname') + API_URL}/tasks`, { headers });
  }

  postNewEvent(event) {
    let tz = new Date().getTimezoneOffset()
    const headers = this.headers();
    return this.http.post(`${window.localStorage.getItem('servname') + API_URL}/tasks/save`, { ...event, tz }, { headers });
  }

  deleteEvent(id) {
    const headers = this.headers();
    return this.http.get(`${window.localStorage.getItem('servname') + API_URL}/tasks/delete/` + id, { headers });
  }
  deleteUserPicture(id) {
    const headers = this.headers();
    return this.http.get(`${window.localStorage.getItem('servname') + API_URL}/users/delimage/` + id, { headers });
  }


  async permissionReject(message) {
    const toast = await this.toastController.create({
      position: 'middle',
      message: message,
      duration: 3000
    });
    toast.present();

  }
}
