import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

/* tslint:disable */
@Component({
 selector: 'formly-field-input',
 template: `
    <ion-item>
        <ion-label position="floating">{{ field.templateOptions.label }}</ion-label>
        <ion-select multiple [formControl]="formControl" [formlyAttributes]="field">
            <ion-select-option [value]="option.value" *ngFor="let option of field.templateOptions.options">{{ option.label }}</ion-select-option>
        </ion-select>
    </ion-item>
 `,
})
export class FormlyFieldIonSelectMultiple extends FieldType {

}
