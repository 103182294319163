import { Location } from '../interfaces/announcement/location';
import { PropertyType } from '../interfaces/announcement/property-type';
import { AnnouncementType } from '../interfaces/announcement/announcement-type';
import { Currency } from '../interfaces/announcement/currency';
import { User } from '../interfaces/users/user';

/* tslint:disable */
export class RequiredPropertyModel {
    id: number;
    user_id: number;
    agent_id: number;
    property_type_id: number;
    announcement_type_id: number;
    location_state_id: number;
    location_community_id: Array<number>;
    location_street_id: Array<number>;

    location_district_id:Array<number>

    rooms: Array<number>;
    price_max: number;
    prie_currency_id: number;
    floor: number;
    area_min: number;
    status_name_id: number;
    description: string;
    location_name: string;
    rent_end_date: string;

    // Pivots
    location_state: Location;
    location_communities: Array<Location>;
    location_streets: Array<Location>;
    property_type: PropertyType;
    announcement_type: AnnouncementType;
    currency: Currency;
    members: Array<User>;
    user: User;
    options: Array<any>;

    constructor() {}

    setData(data: any) {
        Object.assign(this, data);
        return this;
    }

    setPivotOptionsEdit(typeId: number, options: Array<any>): Array<number> | null {
        const arr = [];
        if (!options) {
            return null;
        }
        options.forEach(option => {
            if (option.type_id === typeId) {
                arr.push(option.id);
            }
        });
        if (arr) {
            return arr;
        } else {
            return null;
        }
    }

    postRequireDPropertyStoreTransform(data: any) {
        data['options'] = [];
        Object.keys(data).forEach(key => {
            if (key.includes('option') && key !== 'options') {
                if (data[key]) {
                    data[key].forEach(optId => {
                        data.options.push(optId);
                    });
                }
            }
        });
        return data;
    }
}
