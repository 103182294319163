import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { StatusName } from '../interfaces/required_properties/status-name';
import { PropertyType } from '../interfaces/announcement/property-type';
import { AnnouncementType } from '../interfaces/announcement/announcement-type';
import { Location } from '../interfaces/announcement/location';
import { TranslateService } from './translate.service';
import { RequiredProperty } from '../interfaces/required_properties/required-property';

const API_URL = environment.API_URL;
let servername = window.localStorage.getItem('servname')

@Injectable({
	providedIn: 'root'
})
export class RequiredPropertiesService {
	/* tslint:disable */
	current_required_property: RequiredProperty;
	lang: string;
	required_property_id: number = null;
	status_id: number = null;
	statuses: Array<StatusName>;
	property_types: Array<PropertyType>;
	announcement_types: Array<AnnouncementType>;
	states: Array<Location>;
	communities: Array<Location>;
	streets: Array<Location>;
	districts: Array<Location>;
	currencies: any;

	constructor(
		private http: HttpClient,
		private translate: TranslateService
	) {
		this.translate.lang.subscribe((lang) => {
			this.lang = lang;
		});
	}

	headers() {
		const headers = new HttpHeaders({
			Authorization: `Bearer ${window.localStorage.getItem('token')}`
		});
		return headers;
	}

	getAllRequiredProperties(): Observable<any> {
		const headers = this.headers();
		return this.http.get(`${window.localStorage.getItem('servname') + API_URL}/required_property/indextrello`, { headers });
	}
	getAllRequiredPropertiesindex(): Observable<any> {
		const headers = this.headers();
		return this.http.get(`${window.localStorage.getItem('servname') + API_URL}/required_property/index`, { headers });
	}

	getAllRequiredPropertiesArchive(): Observable<any> {
		const headers = this.headers();
		return this.http.get(`${window.localStorage.getItem('servname') + API_URL}/required_property/archive`, { headers });
	}

	createStatusChange(): Observable<any> {
		const headers = this.headers();
		return this.http.post(
			`${window.localStorage.getItem('servname') + API_URL}/required_property/change_status`,
			{ required_property_id: this.required_property_id, status_id: this.status_id },
			{ headers }
		);
	}

	getRequiredPropertyCreate(): Promise<any> {
		return new Promise((resolve) => {
			const headers = this.headers();
			this.http.get(`${window.localStorage.getItem('servname') + API_URL}/required_property/${this.lang}/create`, { headers }).subscribe((res) => {
				this.statuses = res['statuses'];
				this.property_types = res['property_types'];
				this.announcement_types = res['announcement_types'];
				this.states = res['states'];
				this.communities = res['communities'];
				this.streets = res['streets'];
				this.districts = res['districts']
				this.currencies = res['currencies']
				resolve({
					statuses: this.statuses,
					property_types: this.property_types,
					announcement_types: this.announcement_types,
					states: this.states,
					communities: this.communities,
					streets: this.streets,
					districts: this.districts,
					currencies: this.currencies
				});
			});
		});
	}

	getRequiredProperty(id: number): Observable<any> {
		const headers = this.headers();
		return this.http.get(`${window.localStorage.getItem('servname') + API_URL}/required_property/edit/` + id, { headers });
	}

	postRequiredPropertyStore(obj: object): Observable<RequiredProperty> {
		const headers = this.headers();
		return this.http.post<RequiredProperty>(`${window.localStorage.getItem('servname') + API_URL}/required_property/store`, obj, { headers });
	}

	postRequiredPropertyUpdate(obj: object, required_property_id: number): Observable<any> {
		const headers = this.headers();
		return this.http.post(`${window.localStorage.getItem('servname') + API_URL}/required_property/update/${required_property_id}`, obj, { headers });
	}

	postRequiredPropertySearch(val: Object, page?): Observable<any> {
		const headers = this.headers();
		return this.http.post(`${window.localStorage.getItem('servname') + API_URL}/required_property/search?page=${page}`,  val , { headers });
	}

	postRequiredPropertyAddAgent(obj: object): Observable<any> {
		const headers = this.headers();
		return this.http.post(`${window.localStorage.getItem('servname') + API_URL}/required_property/agents_add`, obj, { headers });
	}

	postDeleteFromMembers(obj: object): Observable<any> {
		const headers = this.headers();
		return this.http.post(`${window.localStorage.getItem('servname') + API_URL}/required_property/agents_delete`, obj, { headers });
	}
	pagination(url) {
		const headers = this.headers();
		return this.http.get(url, { headers });
	}
	paginationPost(page, val?, archive?) {
		const headers = this.headers();
		return this.http.post(`${window.localStorage.getItem('servname') + API_URL}/required_property/search?page=${page}`, {val,archive}, { headers });
	}

	paginationGet(url) {
		const headers = this.headers();
		return this.http.get(url, { headers });
	}

	changeTab(obj) {
		const headers = this.headers();
		return this.http.post(`${window.localStorage.getItem('servname') + API_URL}/required_property/ann_status_change`, obj, { headers });
	}

	setAnn(obj) {
		const headers = this.headers();
		return this.http.post(`${window.localStorage.getItem('servname') + API_URL}/required_property/addann`, obj, { headers });
	}

	delAnn(obj) {
		const headers = this.headers();
		return this.http.post(`${window.localStorage.getItem('servname') + API_URL}/required_property/delann`, obj, { headers });
	}
	deleteProperty(id) {
		const headers = this.headers();
		return this.http.get(`${window.localStorage.getItem('servname') + API_URL}/required_property/delete/` + id, { headers });
	}

	postFiles(files) {
		const headers = this.headers();
		return this.http.post(`${window.localStorage.getItem('servname') + API_URL}/required_property/file/save`, files, { headers });

	}
	sendIDs(ids) {
		const headers = this.headers();
		return this.http.post(`${window.localStorage.getItem('servname') + API_URL}/required_property/sendann`, ids, { headers });
	}

	deletePic(id) {
		const headers = this.headers();
		return this.http.get(`${window.localStorage.getItem('servname') + API_URL}/file/delete/` + id, { headers });
	}

	downloadExcel(id) {
		const headers = this.headers();
		return this.http.post(`${window.localStorage.getItem('servname') + API_URL}/required_property/reqanntoexcel`,id , { headers });
	}

}
