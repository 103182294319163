/* tslint:disable */
export class AnnouncementTypeModel {
    id: number;
    label1: string;
    label2: string;

    constructor() { }

    setData(data: any) {
        Object.assign(this, data);
        return this;
    }

    toOptionsLabel1(items: this[]) {
        const arr = [];
        items.forEach(item => {
            arr.push({value: item.id, label: item.label1});
        });
        return arr;
    }

    toOptionsLabel2(items: this[]) {
        const arr = [];
        items.forEach(item => {
            arr.push({value: item.id, label: item.label2});
        });
        return arr;
    }
}
